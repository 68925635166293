import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/sidebar/Sidebar";
import { useWindowSize } from "../hook/useWindowSize";
import { Outlet } from "react-router-dom";
import { checkIsSubscribed } from "../constant/Utils";
import { useCookies } from "react-cookie";
import { Loader, ModalUi } from "../primitives";
import Parse from "parse";
const HomeLayout = () => {
  const [cookies] = useCookies();
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(true);
  const [isUserValid, setIsUserValid] = useState({
    status: false,
  });
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    localStorage.setItem("accesstoken", cookies?.accesstoken);
    (async () => {
      setIsLoader(true);
      try {
        const userRes = await Parse.User.become(cookies?.accesstoken);
        const userExist = userRes && JSON.parse(JSON.stringify(userRes));
        if (userExist) {
          localStorage.setItem("userName", userExist?.name || "");
          localStorage.setItem("profileImg", userExist?.ProfilePic || "");
          const user = await Parse.Cloud.run("getUserDetails");
          if (user) {
            const jsonUser = JSON.stringify(user);
            localStorage.setItem("Extand_Class", jsonUser);
          }
          const res = await checkIsSubscribed();
          if (res?.isSubscribe === false) {
            setIsUserValid({
              status: true,
              message: "You don't have access this application.",
              title: "Upgrade",
              buttonTitle: "Upgrade now",
            });
          } else {
            setIsUserValid({
              status: false,
            });
            setIsLoader(false);
          }
        } else {
          const getMainDomain = cookies?.main_Domain;
          if (getMainDomain) {
            setIsUserValid({
              status: true,
              message: "Your session has expired.",
              title: "Session Expired",
              buttonTitle: "  Login",
            });
          } else {
            setIsUserValid({
              status: true,
              message: "You don't have access.",
              title: "Access denied",
            });
          }
        }
      } catch (err) {
        const getMainDomain = cookies?.main_Domain;
        if (getMainDomain) {
          setIsUserValid({
            status: true,
            message: "Your session has expired.",
            title: "Session Expired",
            buttonTitle: "  Login",
          });
        } else {
          setIsUserValid({
            status: true,
            message: "You don't have access.",
            title: "Access denied",
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSidebar = () => {
    setIsOpen((value) => !value);
  };
  useEffect(() => {
    if (width && width <= 768) {
      setIsOpen(false);
    }
  }, [width]);

  const closeSidebar = () => {
    if (width <= 768) {
      setIsOpen(false);
    }
  };

  const handleRedirect = (type) => {
    const getMainDomain = cookies?.main_Domain;
    if (getMainDomain) {
      if (type === "Upgrade now") {
        const url = `${getMainDomain}/subscription`;
        window.open(url);
      } else {
        window.open(getMainDomain);
      }
    }
  };

  return (
    <div>
      <div className="sticky top-0 z-[501]">
        {!isLoader && <Header showSidebar={showSidebar} />}
      </div>

      <>
        {isLoader ? (
          <div className="flex h-[100vh] justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="flex md:flex-row flex-col z-50">
              <Sidebar isOpen={isOpen} closeSidebar={closeSidebar} />

              <div
                id="renderList"
                className="relative h-screen flex flex-col justify-between w-full overflow-y-auto"
              >
                <div className="bg-base-200 p-3">{<Outlet />}</div>
                <div className="z-30">
                  <Footer />
                </div>
              </div>
            </div>
          </>
        )}
      </>
      <ModalUi
        title={isUserValid.title}
        isOpen={isUserValid.status}
        showClose={false}
      >
        <div className="flex flex-col justify-center items-center py-4 md:py-5 gap-5">
          <p className="text-xl font-normal">{isUserValid.message}</p>
          {isUserValid?.buttonTitle && (
            <button
              onClick={() => handleRedirect(isUserValid.buttonTitle)}
              className="op-btn op-btn-primary"
            >
              {isUserValid.buttonTitle}
            </button>
          )}
        </div>
      </ModalUi>
    </div>
  );
};

export default HomeLayout;
