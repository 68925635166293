import { useEffect, lazy, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomeLayout from "./layout/HomeLayout.js";
import { LazyPage, Loader } from "./primitives";
import PageNotFound from "./pages/PageNotFound.js";
import Parse from "parse";
import { useCookies } from "react-cookie";
const Home = lazy(() => import("./pages/Home"));
const API = lazy(() => import("./pages/API"));
const Invoice = lazy(() => import("./pages/Invoice"));
const Preferences = lazy(() => import("./pages/Preferences"));
const Subscriptions = lazy(() => import("./pages/Subscriptions.js"));
const Webhook = lazy(() => import("./pages/Webhook"));
const Mail = lazy(() => import("./pages/Mail.js"));
const Organizations = lazy(() => import("./pages/Organizations.js"));
const OrgAdmins = lazy(() => import("./pages/OrgAdmins.js"));
const FileAdapter = lazy(() => import("./pages/FileAdapter.js"));
const PfxFile = lazy(() => import("./pages/PfxFile.js"));

const AppLoader = () => {
  return (
    <div className="flex justify-center items-center h-[100vh]">
      <Loader />
    </div>
  );
};
function App() {
  const [cookies] = useCookies();
  const [isloading, setIsLoading] = useState(true);

  const serverUrl =
    cookies?.server_url || "https://app.opensignlabs.com/api/app/";
  const appId = cookies?.parse_app_id || "legadranaxn";
  // console.log("cookies", cookies, cookies?.server_url);
  Parse.initialize(appId);
  Parse.serverURL = serverUrl;
  useEffect(() => {
    saveLocalStorage();
    // eslint-disable-next-line
  }, []);

  const saveLocalStorage = async () => {
    localStorage.setItem("parseAppId", appId);
    localStorage.setItem("baseUrl", serverUrl);
    localStorage.setItem(
      "appLogo",
      "/static/media/logo.2a7bff0c1189183fafe7.png"
    );
    setIsLoading(false);
  };
  return (
    <div className="bg-base-200">
      {isloading ? (
        <AppLoader />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route element={<HomeLayout />}>
              <Route path="/" element={<LazyPage Page={Home} />} />
              <Route path="/api" element={<LazyPage Page={API} />} />
              <Route path="/invoices" element={<LazyPage Page={Invoice} />} />
              <Route
                path="/preferences"
                element={<LazyPage Page={Preferences} />}
              />
              <Route
                path="/subscriptions"
                element={<LazyPage Page={Subscriptions} />}
              />
              <Route path="/webhook" element={<LazyPage Page={Webhook} />} />
              <Route path="/mail" element={<LazyPage Page={Mail} />} />
              <Route
                path="/organizations"
                element={<LazyPage Page={Organizations} />}
              />
              <Route
                path="/orgadmins"
                element={<LazyPage Page={OrgAdmins} />}
              />
              <Route
                path="/fileadapter"
                element={<LazyPage Page={FileAdapter} />}
              />
              <Route path="/signingcertificate" element={<LazyPage Page={PfxFile} />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
