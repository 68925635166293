import axios from "axios";

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const toDataUrl = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      resolve(e.target.result);
    };
  });
};
export function sanitizeFileName(fileName) {
  // Remove spaces and invalid characters
  return fileName.replace(/[^a-zA-Z0-9._-]/g, "");
}

//function to get subcripition details from Extand user class
export async function checkIsSubscribed() {
  const extUserData = JSON.parse(localStorage.getItem("Extand_Class"));
  const data = { extUserId: extUserData?.objectId };
  const isSubscribe = await axios
    .post(
      `${localStorage.getItem("baseUrl")}functions/getsubscriptions`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
          sessiontoken: localStorage.getItem("accesstoken"),
        },
      }
    )
    .then((Listdata) => {
      const json = Listdata.data;
      if (json && json?.result?.status === "success") {
        const result = json.result?.result;
        const billingDate = result?.Next_billing_date?.iso;
        if (result?.SubscriptionDetails && new Date(billingDate) > new Date()) {
          return {
            isSubscribe: true,
            plan:
              result?.SubscriptionDetails?.data?.subscription?.plan
                ?.plan_code || "",
            adminId: result?.ExtUserPtr?.objectId || "",
          };
        } else {
          return { isSubscribe: false, plan: "", adminId: "" };
        }
      }
    })
    .catch((err) => {
      console.log("Err in fetch subscription", err);
      return { isSubscribe: false, plan: "", adminId: "" };
    });

  return isSubscribe;
}

export async function fetchSubscriptionInfo() {
  try {
    const Extand_Class = localStorage.getItem("Extand_Class");
    const extClass = Extand_Class && JSON.parse(Extand_Class);
    if (extClass) {
      const extUser = extClass.objectId;
      const baseURL = localStorage.getItem("baseUrl");
      const url = `${baseURL}functions/getsubscriptions`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
        sessionToken: localStorage.getItem("accesstoken"),
      };
      const params = { extUserId: extUser };
      const tenatRes = await axios.post(url, params, { headers: headers });

      const price =
        tenatRes.data?.result?.result?.SubscriptionDetails?.data?.subscription
          ?.plan?.price;
      const totalPrice =
        tenatRes.data?.result?.result?.SubscriptionDetails?.data?.subscription
          ?.amount;
      const planId =
        tenatRes.data?.result?.result?.SubscriptionDetails?.data?.subscription
          ?.subscription_id;
      const plan_code = tenatRes.data?.result?.result?.PlanCode;
      const totalAllowedUser = tenatRes.data?.result?.result?.AllowedUsers || 0;
      const adminId =
        tenatRes?.data?.result?.result?.ExtUserPtr?.objectId || "";
      return {
        status: "success",
        price: price,
        totalPrice: totalPrice,
        planId: planId,
        plan_code: plan_code,
        totalAllowedUser: totalAllowedUser,
        adminId: adminId,
      };
    }
  } catch (err) {
    console.log("Err in fetch subscription", err);
    return { status: "error", error: err };
  }
}
// `copytoData` is used to copy details to clipboard
export const copytoData = (text) => {
  // navigator.clipboard.writeText(text);
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    // Fallback for browsers that don't support navigator.clipboard
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }
};
// `generateTitleFromFilename` to generate Title of document from file name
export function generateTitleFromFilename(filename) {
  try {
    // Step 1: Trim whitespace
    let title = filename.trim();

    // Step 2: Remove the file extension (everything after the last '.')
    const lastDotIndex = title.lastIndexOf(".");
    if (lastDotIndex > 0) {
      title = title.substring(0, lastDotIndex);
    }

    // Step 3: Replace special characters (except Unicode letters, digits, spaces, and hyphens)
    title = title.replace(/[^\p{L}\p{N}\s-]/gu, " ");

    // Step 4: Replace multiple spaces with a single space
    title = title.replace(/\s+/g, " ");

    // Step 5: Capitalize first letter of each word (Title Case), handling Unicode characters
    title = title.replace(
      /\p{L}\S*/gu,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

    // Step 6: Restrict length of title (optional, let's say 100 characters)
    if (title.length > 100) {
      title = title.substring(0, 100).trim();
    }

    // Step 7: Handle empty or invalid title by falling back to "Untitled Document"
    if (!title || title.length === 0) {
      return "Untitled Document";
    }

    return title;
  } catch (error) {
    // Handle unexpected errors gracefully by returning a default title
    console.error("Error generating title from filename:", error);
    return "Untitled Document";
  }
}

export const signatureTypes = [
  { name: "draw", enabled: true },
  { name: "typed", enabled: true },
  { name: "upload", enabled: true },
  { name: "default", enabled: true }
];
