import React from "react";
import { useCookies } from "react-cookie";

const SubscribeCard = ({ plan, price }) => {
  const [cookies] = useCookies();
  const premiumPrice = price ? "$" + price : "$9.99";
  const addonPrice = "$0.15";
  const plandName = plan ? plan.toLowerCase() : "Paid";
  const body = plandName?.includes("team")
    ? "Unlock the full power of collaboration! Create unlimited organizations, teams, and hierarchies. Share templates seamlessly across teams and assign custom user roles. Elevate your workflow today!"
    : `Unlock premium features starting at just ${premiumPrice}/month. Enjoy enhanced performance and only ${addonPrice} per additional credit after your included premium credits.`;
  const handleNavigation = () => {
    const getMainDomain = cookies?.main_Domain;
    if (getMainDomain) {
      window.open(`${getMainDomain}/subscription`, "_self");
    }
  };
  return (
    <div className="op-card op-bg-primary text-primary-content w-full shadow-lg">
      <div className="op-card-body">
        <h2 className="op-card-title">
          Upgrade to {plan ? plan : "Paid"} Plan
        </h2>
        <p>{body}</p>
        <div className="op-card-actions justify-end">
          <button onClick={handleNavigation} className="op-btn op-btn-accent">
            Upgrade Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscribeCard;
