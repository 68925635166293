import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import SocialMedia from "./SocialMedia";
import { premiumMenu, sideBarMenu } from "../../json/menu";
import dp from "../../assets/images/dp.png";
import { useCookies } from "react-cookie";
import Submenu from "./Submenu";
const Sidebar = ({ isOpen, closeSidebar }) => {
  const [cookies] = useCookies();
  const [menuList, setmenuList] = useState([]);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const username = localStorage.getItem("userName") || "";
  const image = localStorage.getItem("profileImg") || dp;
  const tenantname = localStorage.getItem("Extand_Class")
  ?  JSON.parse(localStorage.getItem("Extand_Class"))?.Company
  : "";
  useEffect(() => {
    if (cookies?.accesstoken) {
      menuItem();
    }
    // eslint-disable-next-line
  }, []);

  const menuItem = () => {
    const localUser =
      localStorage.getItem("Extand_Class") &&
      JSON.parse(localStorage.getItem("Extand_Class"));
    if (localUser?.UserRole === "contracts_Admin") {
      setmenuList([...sideBarMenu, ...premiumMenu]);
    } else {
      setmenuList(sideBarMenu);
    }
  };
  const toggleSubmenu = (title) => {
    setSubmenuOpen({ [title]: !submenuOpen[title] });
  };
  const handleMenuItem = () => {
    closeSidebar();
    setSubmenuOpen({});
  };

  return (
    <aside
      className={`absolute lg:relative bg-base-100 h-screen overflow-y-auto transition-all z-[500] shadow-lg hide-scrollbar
     ${isOpen ? "w-full md:w-[300px]" : "w-0"}`}
    >
      <div className="flex px-2 py-2.5 gap-2 items-center shadow-md">
        <div className="w-[75px] h-[75px] rounded-full ring-[2px] ring-offset-2 ring-gray-400 overflow-hidden">
          <img
            className="w-full h-full object-contain"
            src={image}
            alt="Profile"
          />
        </div>
        <div>
          <p className="text-[14px] font-bold text-base-content">{username}</p>
          <p
            className={`cursor-pointer text-[12px] text-base-content ${
              tenantname ? "mt-2" : ""
            }`}
          >
            {tenantname}
          </p>
        </div>
      </div>
      <nav
        className="op-menu op-menu-sm"
        aria-label="OpenSign Sidebar Navigation"
      >
        <ul
          className="text-sm"
          role="menubar"
          aria-label="OpenSign Sidebar Navigation"
        >
          {menuList.map((item) =>
            !item.children ? (
              <Menu
                key={item.title}
                item={item}
                isOpen={isOpen}
                closeSidebar={handleMenuItem}
              />
            ) : (
              <Submenu
                key={item.title}
                item={item}
                closeSidebar={closeSidebar}
                toggleSubmenu={toggleSubmenu}
                submenuOpen={submenuOpen}
              />
            )
          )}
        </ul>
      </nav>
      <footer className="mt-3 flex justify-center items-center text-[25px] text-base-content gap-3">
        <SocialMedia />
      </footer>
    </aside>
  );
};

export default Sidebar;
