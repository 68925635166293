import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { openInNewTab } from "../constant/Utils";

const Tooltip = ({ id, message, url, iconColor, isSubscribe }) =>
  url ? (
    <button onClick={() => openInNewTab(url)} className={"text-center"}>
      <sup>
        <i
          className="fa-light fa-question rounded-full text-[13px] border-[1px] px-[4px] py-[1.5px]"
          style={{
            borderColor: iconColor ? iconColor : "#33bbff",
            color: iconColor ? iconColor : "#33bbff",
          }}
        ></i>
      </sup>
    </button>
  ) : (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a
        role="button"
        data-tooltip-id={id ? id : "my-tooltip"}
        data-tooltip-content={message}
        className="z-50"
      >
        <sup>
          <i
            className="fa-light fa-question rounded-full text-[13px] border-[1px] px-[4px] py-[1.5px]"
            style={{
              borderColor: iconColor ? iconColor : "#33bbff",
              color: iconColor ? iconColor : "#33bbff",
            }}
          ></i>
        </sup>
      </a>
      {/* eslint-enable jsx-a11y/anchor-is-valid */}
      <ReactTooltip id={id ? id : "my-tooltip"} className="max-w-[200px]" />
    </>
  );

export default Tooltip;
